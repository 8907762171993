<template>
  <div class="about">
      <!-- 切换区域 -->
    <div class="active-wrap">
      <span @click="language_active('english')">English</span>
      <span @click="language_active('chinese')">中文</span>
    </div>

    <!-- logo菜单区域 -->
    <nav-bar @navBtn="navBtn"/>

    <!-- 菜单导航区域 -->
    <menu-page :flag="flag" v-show="menuShow == 1" />

    <div v-show="menuShow == 0">
      <!-- 标题区域 -->
    <div class="title-wrap"  :style="{ fontFamily: flag == 1 ? 'Montserrat-Bold' : 'NotoSansSC-Bold' }">
        {{flag == 1? 'About Us' : '关于飞狮工作室'}}
    </div>

    <!-- 内容区域 -->
    <div class="content-wrap">
        <span  :style="{ fontFamily: flag == 1 ? 'Montserrat-Regular' : 'NotoSansSC-Regular' }">{{flag == 1 ? textActive :text}}</span>
        <span :style="{ fontFamily: flag == 1 ? 'Montserrat-Regular' : 'NotoSansSC-Regular' }">{{flag == 1 ? text2Active :text2}}</span>
        <span :style="{ fontFamily: flag == 1 ? 'Montserrat-Regular' : 'NotoSansSC-Regular' }">{{flag == 1 ? text3Active :text3}}</span>
    </div>

    <!-- 分割线 -->
    <div class="partition"></div>

    <!-- 联系区域 -->
    <div class="contact-wrap">
        <p :style="{ fontFamily: flag == 1 ? 'Montserrat-Regular' : 'NotoSansSC-Bold' }">{{flag == 1 ? 'Need to contact us?' : '需要联络我们？'}}</p>
        <span  :style="{ fontFamily: flag == 1 ? 'Montserrat-Regular' : 'NotoSansSC-Regular' }">{{flag == 1 ? contactTextActive : contactText}}</span>
        <span  :style="{ fontFamily: flag == 1 ? 'Montserrat-Regular' : 'NotoSansSC-Regular' }">{{flag == 1 ? contactText2Active : contactText2}}</span>
        <span  :style="{ fontFamily: flag == 1 ? 'Montserrat-Regular' : 'NotoSansSC-Regular' }">{{flag == 1 ? contactText3Active : contactText3}}</span>
        <span  :style="{ fontFamily: flag == 1 ? 'Montserrat-Regular' : 'NotoSansSC-Regular' }">{{flag == 1 ? contactText4Active : contactText4}}</span>
    </div>

    <!-- 底部区域 -->
		<div class="footer-wrap">
			<div class="top">
				<img src="@/assets/icon/black.svg" alt="" />
				<p>MetaWords</p>
			</div>
			<span @click="footJump(1)">{{flag == 1 ? 'Marketplace' : '交易市场'}}</span>
			<span @click="footJump(2)">{{flag == 1 ? 'About Us' : '关于我们'}}</span>
			<span @click="footJump(3)">{{flag == 1 ? 'Become a Partner' : '成为原住民'}}</span>
			
			<div class="bottom">
				<img @click="footJump(4)" src="@/assets/icon/bottom-logo.png" alt="" />
				<img @click="footJump(5)" src="@/assets/icon/bottom-logo2.png" alt="" />
				<img @click="footJump(6)" src="@/assets/icon/bottom-logo3.png" alt="" />
			</div>
		</div>
    </div>
  </div>
</template>

<script>
import menuPage from "@/components/menuPage.vue";
import navBar from "@/components/navBar.vue";
export default {
  components: {
    menuPage,
    navBar,
  },
    data() {
        return {
            flag: 2,
            menuShow: 0,	//菜单切换
            text: '飞狮工作室(Flying Lion Lab)，是徐冰与狮子集团控股共同成立的元宇宙创新实验室。飞狮工作室致力于将徐冰的艺术作品以NFT的形式在元宇宙中延展，创造出属于未来的艺术生态',
            textActive: 'Flying Lion Lab is an innovative Metaverse laboratory co-founded by artist Xu Bing and Lion Group Holding Ltd. Flying Lion Lab undertakes the journey to expand Xu Bing’s artwork into Non-Fungible Tokens (NFTs) and is responsible for helping them develop within the Metaverse – creating a new art ecosystem.',
            text2: 'MetaWords是飞狮工作室推出的第一个作品，是《地书》以NFT形式的延伸，具有不断生长、无限开放的本质。飞狮工作室希望把MetaWords 打造成属于元宇宙的语言体系，一个人们可以自由参与的、共创共建的语言生态。',
            text2Active: 'MetaWords is Flying Lion Lab’s first project and is an evolution of Xu Bing’s artwork. This NFT project has the potential to continuously grow and flourish, as an infinite work of art. Flying Lion Lab hopes it will serve as an entirely new language system of the future Metaverse, the “Metalanguage” of the Metaverse. MetaWords is a language ecosystem that is truly open, where the community can freely participate, grow, and develop together. ',
            text3: '飞狮工作室做为第一批参与NFT领域的先锋者，在徐冰艺术版图基础上，与众多IP资源已经达成战略合作，拥有全球顶尖的艺人经纪公司、美术馆、博物馆和知名艺术家的IP资源。团队成员均具有海外名校学历及工作背景，拥有大型金融机构、互联网公司及艺术画廊的工作经验。',
            text3Active: 'At the frontier of exploring the emerging field of the NFT, centered around the cornerstone that Xu Bing has paved, Flying Lion Lab has already started building strategic partnerships. These include various intellectual property resources such as top talent agencies, art galleries, museums, and other renowned artists. The Flying Lion Lab team is comprised of a mix of talented individuals with diverse backgrounds, including those in key financial institutions, multi-national technology companies as well as art galleries.',
            contactText: '如果您对于我们的项目有疑问，或寻求合作，可以通过以下方式联系飞狮工作室：',
            contactTextActive: 'If you have any inquiries about MetaWords or would like to partner with us, please contact us via the following methods: ',
            contactText2: '1.发送电邮至metawords@flyinglion.io',
            contactText2Active: '1. Email us at metawords@flyinglion.io',
            contactText3: '2.将疑问发送在Twitter平台并@MetaWordsNFT',
            contactText3Active: ' 2. Tweet us your questions on Twitter @MetaWordsNFT',
            contactText4: '3.加入我们的社团，在相应板块和其他社群的朋友交流。',
            contactText4Active: ' 3. Join our Discord server and connect with other community members',
        }
    },
    mounted() {
    this.flag = Number(this.$route.query.flag)
  },
    methods: {
		// 中英文切换
    language_active(type) {
      console.log(type, "type");
      if (type == "english") {
        this.flag = 1;
      } else {
        this.flag = 2;
      }
    },
    // 菜单按钮
    navBtn(data) {
      this.menuShow = data
    },
	// 底部跳转
    footJump(num) {
      switch (num) {
        case 1:
          window.open("https://lionnft.net/register");
          break;
        case 2:
          this.$router.push("./About");
          break;
        case 3:
          this.$router.push("./aborigines");
          break;
        case 4:
          window.open("https://t.me/MetaWordsNFTDiscussion");
          break;
        case 5:
          window.open("https://twitter.com/MetaWordsNFT");
          break;
        case 6:
          window.open("https://discord.com/invite/FFmUt3rp5J");
          break;
      }
    },
	}

}
</script>

<style lang="scss" scoped>
// 标题区域样式
.active-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 22px;
  height: 30px;
  background: #000000;
  span {
    width: 89px;
    text-align: right;
    font-size: 18px;
    font-family: Montserrat-Regular;
    font-weight: 400;
    color: #ffffff;
  }
}
// 标题区域
.title-wrap {
    margin: 53px 0 45px 26px;
    width: 220px;
    height: 30px;
    font-size: 26px;
    font-family: NotoSansSC-Bold, NotoSansSC;
    font-weight: bold;
    color: #000000;
    line-height: 37px;
}

// 内容区域样式
.content-wrap {
    margin: 0 22px 0 26px;
    span {
        display: block;
        width: 366px;
        font-size: 19px;
        font-family: NotoSansSC-Regular, NotoSansSC;
        font-weight: 400;
        color: #000000;
        line-height: 27px;
    }
    span:nth-child(2) {
        margin: 20px 0;
    }
}

.partition {
    margin: 73px 0 50px 26px;
    width: 37px;
    height: 1px;
    border: 5px solid #181726;
    background: #181726;
}

// 联系区域样式
.contact-wrap {
    margin: 0 22px 26px;
    p {
        display: block;
        margin-bottom: 59px;
        height: 30px;
        font-size: 26px;
        font-family: NotoSansSC-Bold, NotoSansSC;
        font-weight: bold;
        color: #000000;
        line-height: 37px;
    }
    span {
        display: block;
        width: 366px;
        font-size: 19px;
        font-family: NotoSansSC-Regular, NotoSansSC;
        font-weight: 400;
        color: #000000;
        line-height: 27px;
    }
}
// 底部区域样式
.footer-wrap {
	padding: 25px 0 0 26px;
	width: 100%;
	height: 387px;
	background: #000000;
	box-sizing: border-box;
		.top {
			display: flex;
			align-items: center;
			img {
				width: 63px;
				height: 63px;
			}
			p {
				margin-left: 9px;
				width: 174px;
				height: 30px;
				font-size: 24px;
				font-family: Montserrat-Bold, Montserrat;
				font-weight: bold;
				color: #FFFFFF;
				line-height: 29px;
			}
		}
		span {
			margin-top: 21px;
			display: block;
			height: 24px;
			font-size: 17px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #FFFFFF;
			line-height: 24px;
			-webkit-background-clip: text;
		}
		.bottom {
			margin-top: 39px;
				img {
					margin-right: 20px;
					width: 41px;
					height: 41px;
				}
		}
}
</style>